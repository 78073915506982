<template>
  <div v-if="hasPower_Step1||hasPower_Step2||hasPower_Step3">
    <van-steps :active="active" @click-step="handleClickStep">
      <van-step>江阴</van-step>
      <van-step>沧州</van-step>
      <van-step>出货</van-step>
    </van-steps>
    <van-field label="标签码" v-model="dlnd01_SignNumber.signNumber" :readonly="true">
      <template #extra v-if="dlnd01_SignNumber.status==2">
        <van-tag type="danger">作废</van-tag>
      </template>
      <template #extra v-else-if="dlnd01_SignNumber.status==1">
        <van-tag type="primary">结单</van-tag>
      </template>
    </van-field>
    <Wap_DLND01_Step1 v-show="active==0" ref="step1" :signNumber="$route.query.signNumber"></Wap_DLND01_Step1>
    <Wap_DLND01_Step2 v-show="active==1" ref="step2" :signNumber="$route.query.signNumber"></Wap_DLND01_Step2>
    <Wap_DLND01_Step3 v-show="active==2" ref="step3" :signNumber="$route.query.signNumber"></Wap_DLND01_Step3>
  </div>
  <van-empty v-else><template #description>
      {{dlnd01_step3.companyName}}
      <div class="textCenter"><a :href="'tel:'+dlnd01_step3.agentPhone">{{dlnd01_step3.agentPhone}}</a></div>
    </template>
  </van-empty>
</template>
  
  <script>
import Vue from "vue";
import Vant from "vant";
import Wap_DLND01_Step1 from "./Wap_DLND01_Step1";
import Wap_DLND01_Step2 from "./Wap_DLND01_Step2";
import Wap_DLND01_Step3 from "./Wap_DLND01_Step3";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  components: { Wap_DLND01_Step1, Wap_DLND01_Step2, Wap_DLND01_Step3 },
  data() {
    return {
      active: 0,
      dlnd01_SignNumber: {},
      hasPower_Step1: false,
      hasPower_Step2: false,
      hasPower_Step3: false,
      hasPower_End: false,
      dlnd01_step3: {},
    };
  },
  methods: {
    handleClickStep(index) {
      let that = this;
      that.active = index;
    },
    void_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要作废吗？" })
        .then(function () {
          that.dlnd01_SignNumber.status = 2;
          that.axios.post("DLND01/Update_SignNumber", that.dlnd01_SignNumber);
        })
        .catch(function () {});
    },
    cancel_void_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要取消作废吗？" })
        .then(function () {
          that.dlnd01_SignNumber.status = 0;
          that.axios.post("DLND01/Update_SignNumber", that.dlnd01_SignNumber);
        })
        .catch(function () {});
    },
    cancel_end_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要取消结单吗？" })
        .then(function () {
          that.dlnd01_SignNumber.status = 0;
          that.axios.post("DLND01/Update_SignNumber", that.dlnd01_SignNumber);
        })
        .catch(function () {});
    },
    end_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要结单吗？" })
        .then(function () {
          that.dlnd01_SignNumber.status = 1;
          that.axios.post("DLND01/Update_SignNumber", that.dlnd01_SignNumber);
        })
        .catch(function () {});
    },
  },
  mounted() {
    let that = this;
    document.title = "沥青动力粘度计测定仪";
    that.axios
      .post("DLND01/Init_SignNumber", {
        onlyText: that.$route.query.signNumber,
      })
      .then(function (response) {
        that.active = response.data.msg;
        that.dlnd01_SignNumber = response.data.data;
      });
    //获取权限
    that.axios
      .post("Base/HasPower", { powerKey: "DLND01_江阴" })
      .then(function (response) {
        that.hasPower_Step1 = response.data.data;
      });

    that.axios
      .post("Base/HasPower", { powerKey: "DLND01_沧州" })
      .then(function (response) {
        that.hasPower_Step2 = response.data.data;
      });
    that.axios
      .post("Base/HasPower", { powerKey: "DLND01_出货" })
      .then(function (response) {
        that.hasPower_Step3 = response.data.data;
      });
    that.axios
      .post("Base/HasPower", { powerKey: "DLND01_结单" })
      .then(function (response) {
        that.hasPower_End = response.data.data;
      });
    if (!that.hasPower_Step1 && !that.hasPower_Step2 && !that.hasPower_Step3) {
      that.axios
        .post("DLND01/GetDLND01_Step3", {
          onlyText: that.$route.query.signNumber,
        })
        .then(function (response) {
          that.dlnd01_step3 = response.data.data;
        });
    }
  },
};
</script>
  
  <style scoped>
.textCenter {
  text-align: center;
}
</style>