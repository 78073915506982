<template>
  <div>
    <van-cell title="屏幕测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.pingMuCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.anJianCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="5V测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.ceShi5V" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="3.3V测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.ceShi33V" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温度传感器测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.wenDuChuanGanQiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="加热输出测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.jiaReShuChuCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="压力传感器测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.yaLiChuanGanQiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="Wifi测试">
      <template #right-icon>
        <van-checkbox v-model="dlnd01_Step1.wifiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="dlnd01_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.dlnd01_SignNumber.status!=0" round block @click="save_DLND01_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.dlnd01_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.dlnd01_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      dlnd01_Step1: {},
    };
  },
  methods: {
    getDLND01_Step1() {
      let that = this;
      that.axios
        .post("/DLND01/GetDLND01_Step1", { onlyText: that.signNumber })
        .then(function (response) {
          that.dlnd01_Step1 = response.data.data;
        });
    },
    save_DLND01_Step1() {
      let that = this;
      that.axios
        .post("DLND01/UpdateDLND01_Step1", that.dlnd01_Step1)
        .then(function (response) {
          that.dlnd01_Step1 = response.data.data;
          that.$notify({ type: "success", message: response.data.msg });
        });
    },
  },
  mounted() {
    let that = this;
    that.getDLND01_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>